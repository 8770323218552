/*
 *  * Author: Abdullah
 *  * Date: 12/02/24, 3:11 PM
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyIntl',
  standalone: true
})
export class CurrencyIntlPipe implements PipeTransform {

  transform(amount: string | number, locale: string = 'en-US', format: string = 'USD'): string {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: format }).format(+amount);
  }

}
