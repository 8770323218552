import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByteSizePipe } from "@app/shared/pipes/bytesize.pipe";
import { IconPipe } from './icon.pipe';
import { SafePipe } from './safe.pipe';
import { HighlightPipe } from '@app/shared/pipes/highlight.pipe';
import { BooleanPipe } from "@app/shared/pipes/boolean.pipe";
import { AgeOfRequestPipe } from './age-of-request.pipe';
import { PropertyKeyPipe } from './property-key.pipe';
import { CurrencyIntlPipe } from './currency-amount.pipe';


@NgModule({
  declarations: [
    SafePipe,
    HighlightPipe
  ],
  imports: [
    CommonModule,
    BooleanPipe,
    ByteSizePipe,
    IconPipe,
    AgeOfRequestPipe,
    PropertyKeyPipe,
    CurrencyIntlPipe
  ],
  exports: [
    ByteSizePipe,
    IconPipe,
    SafePipe,
    HighlightPipe,
    BooleanPipe,
    ByteSizePipe,
    AgeOfRequestPipe,
    PropertyKeyPipe,
    CurrencyIntlPipe
  ]
})
export class PipeModule { }
