import { Status } from "../const/roam-status.const";

export const iconSourcePath = `assets/svg`;

export const statusCodeNumber = new Map<number, string>([
  [0, "#B5781E"],
  [1, "#2AB51E"],
  [2, "#295AD8"],
  [3, "#949494"],
  [4, "#2AB51E"],
]);

export const colorStatus = new Map<string | number, string>([
  [Status.ACTIVE, "#2AB51E"],
  [Status.APPROVED, "#2AB51E"],
  [Status.CANCELED, "#D40101"],
  [Status.COMPLETED, "#2AB51E"],
  [Status.COMPLETE, "#2AB51E"],
  [Status.CREATED, "#295AD8"],
  [Status.DONE, "#219653"],
  [Status.ESCALATE, "#D40101"],
  [Status.IGNORE, "#636E72"],
  [Status.IN_PROGRESS, "#295AD8"],
  [Status.NEW, "#295AD8"],
  [Status.NOT_STARTED, "#b5781e"],
  [Status.ON_GOING, '#2F80ED'],
  [Status.OPEN, '#D40101'],
  [Status.PENDING, "#B5781E"],
  [Status.RESOLVED, "#2AB51E"],
  [Status.TODO, "#636E72"],
  [Status.VIOLATION, "#B5301E"],

  // Dummy Status
  ["sent", "#B5781E"],
  ["countersign", "#2AB51E"],
  ["draft", "#949494"],
  ["private", "#666666"],
  ["approved", "#2AB51E"],
  ["inactive", "#949494"],
  ["unpaid", "#B5781E"],
  ["paid", "#2AB51E"],

  ["assigned", "#866385"],
  ["on my way", "#866385"],
  ["finished", "#2AB41E"],
  ...statusCodeNumber,
]);

export const iconSource = new Map([
  ["ACTIVE",`${iconSourcePath}/check-icon-green.svg`],
  ["1", `${iconSourcePath}/check-icon-green.svg`],
  ["0", `${iconSourcePath}/ic-time-orange.svg`],
  ["sent", `${iconSourcePath}/ic-time-orange.svg`],
  ["countersign", `${iconSourcePath}/check-icon-green.svg`],
  ["draft", `${iconSourcePath}/ic-time-grey.svg`],
  ["approved", `${iconSourcePath}/check-icon-green.svg`],
  ["inactive", `${iconSourcePath}/ic-ignore.svg`],
]);


export const progressStatus = new Map([
  ['TODO', '#636E72'],
  ['ON_GOING', '#2F80ED'],
  ['DONE', '#219653']
])
