import { CommonModule } from "@angular/common";
import {
  Component,
  ContentChild,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule, Sort } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { EmptyStateComponent } from "@app/shared/components/empty-state/empty-state.component";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { PipeModule } from "@app/shared/pipes/pipe.module";
import { iconLib } from "../../../../core/const/roam-icon";
import { TableConfigModel } from "../model";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    EmptyStateComponent,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    PipeModule,
  ],
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit{
  @ContentChild("customTemplate")
  public customTemplate!: TemplateRef<any> | null;

  @ContentChild("selectionTemplate")
  public selectionTemplate!: TemplateRef<any> | null;

  @Input() public additionalClass?: string;
  @Input() public emptyState: IEmptyState = {
    title: "",
    subtitle: "",
    icon: "",
  };
  @Input() public httpPagination?: boolean;
  @Input() public isLoading?: boolean;
  @Input() public localPagination?: boolean;
  @Input() public tableConfig!: TableConfigModel;
  @Input() public emptyRow!: boolean;
  @Input() public category: "house" | null | "association" = "association";
  @Input() public totalRecords!: number;
  @Input() emptyRecord!: boolean;

  @Output()
  public mouseOverChange: EventEmitter<any>;

  @Output()
  public pageChange: EventEmitter<PageEvent>;

  @Output()
  public selectData: EventEmitter<any>;

  @Output()
  public sortChange: EventEmitter<Sort>;

  @ViewChild("paginator")
  public set Paginator(paginator: MatPaginator) {
    if (paginator) {
      this.dataSource = new MatTableDataSource(this.tableConfig.dataSource);
      this.dataSource.data = this.tableConfig.dataSource;
      this.dataSource.paginator = paginator;
    }
  }

  public dataSource!: MatTableDataSource<any>;
  public defaultIcon = iconLib.desktop.src;

  constructor() {
    this.mouseOverChange = new EventEmitter();
    this.pageChange = new EventEmitter();
    this.selectData = new EventEmitter();
    this.sortChange = new EventEmitter();
  }

  ngOnInit(): void {
    this.setRecordEmptyState()
  }

  public getPropertyValue(obj: any, key: string): any {
    if (!key) return obj;

    const props = key.split(".");

    for (let i = 0; i < props.length; i++) {
      if (obj === null || obj === undefined) {
        return "N/A";
      }
      obj = obj[props[i]];
    }

    return obj !== undefined ? obj : null;
  }

  public displayValue(value: any): any {
    return value !== null && value !== undefined ? value : '-';
  }

  public disableClick(event: Event, tableKey: string) {
    (tableKey === "action" || tableKey === "selection") &&
      event.stopPropagation();
  }

  setRecordEmptyState(): void {
    if(this.emptyRecord) {
      this.category = null;
      this.emptyState.title = "No Records Found";
      this.emptyState.subtitle = "You don't have any records yet"
    }
  }
}
